<template>
  <div class="center">
    <div class="header_title">下单人信息</div>
    <div class="company">
      <div class="name">{{ alldata.consumerName }}</div>
      <div class="tell">
        <i class="hy-icon-dianhua"></i> {{ alldata.consumerTelephone }}
      </div>
    </div>
    <div class="header_title">订单信息</div>
    <div class="order">
      <div class="left">
        <img src="@/assets/images/dingdan.jpg" />
      </div>
      <div class="table">
        <el-descriptions class="margin-top" :column="3" size="medium" border>
          <el-descriptions-item :span="3" label-class-name="my-label">
            <template slot="label"> 状态： </template>
            {{ applyStatus[alldata.applyStatus] }}
          </el-descriptions-item>
          <el-descriptions-item :span="3" label-class-name="my-label">
            <template slot="label"> 订单创建时间： </template>
            {{ alldata.createTime }}
          </el-descriptions-item>
          <el-descriptions-item
            v-if="alldata.applyStatus != 0"
            :span="3"
            label-class-name="my-label"
          >
            <template slot="label">
              订单{{ alldata.applyStatus == 1 ? "通过" : "拒绝" }}时间：
            </template>
            {{ applydata.auditTime.replace("T", " ") }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="header_title">申请资料</div>
    <div class="car">
      <el-descriptions class="margin-top" :column="7" size="medium" border>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label">
            <i style="color: red">*</i> 申请编号：
          </template>
          {{ applydata.applyCode }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 融资企业名称：
          </template>
          {{ applydata.coreCompanyName }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 申请日期：
          </template>
          {{ applydata.applyDate }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 申请金额：
          </template>
          {{ applydata.applyAmount }}
        </el-descriptions-item>
        <el-descriptions-item :span="7" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 申请产品名称：
          </template>
          {{ applydata.productName }}
        </el-descriptions-item>
        <el-descriptions-item :span="7" label-class-name="my-labels">
          <template slot="label"><i style="color: red">*</i> 备注： </template>
          {{ applydata.remark }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 企业名称：
          </template>
          {{ applydata.companyName }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 注册时间：
          </template>
          {{ applydata.registerTime }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 社会信用代码：
          </template>
          {{ applydata.socialCode }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 法人姓名：
          </template>
          {{ applydata.legalName }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 注册资本（万元）：
          </template>
          {{ applydata.registerCapital }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 注册地址：
          </template>
          {{ applydata.registerAddress }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 法人手机号：
          </template>
          {{ applydata.legalPhone }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 法人证件号码：
          </template>
          {{ applydata.legalIdcard }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 企业总资产（万元）：
          </template>
          {{ applydata.companyTotalAssets }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 企业负债总额（万元）：
          </template>
          {{ applydata.companyDebt }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 实际控制人姓名：
          </template>
          {{ applydata.actualControllerName }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 实际控制人证件号码：
          </template>
          {{ applydata.actualControllerIdcard }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 前一年度营销收入（万元）：
          </template>
          {{ applydata.previousYearIncome }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 实际控制人手机号：
          </template>
          {{ applydata.actualControllerPhone }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 前两年度营销收入（万元）：
          </template>
          {{ applydata.twoYearsIncome }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 与核心企业合作年限：
          </template>
          {{ applydata.companyCooperationYears }}
        </el-descriptions-item>
      </el-descriptions>
      <div style="height: 36px; wdith: 100%"></div>
      <el-descriptions class="margin-top" :column="7" size="medium" border>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 前两年度与买方交易规模（万元）：
          </template>
          {{ applydata.twoYearsTradeSize }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 前一年度与买方交易规模（万元）：
          </template>
          {{ applydata.previousYearTradeSize }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 前三年度与买方交易规模（万元）：
          </template>
          {{ applydata.threeYearsTradeSize }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 前一年度应收账款总额（万元）：
          </template>
          {{ applydata.previousYearReceivePayment }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 平均帐期（月）：
          </template>
          {{ applydata.averageAccountPeriod }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 前一年度退换货比率（%）：
          </template>
          {{ applydata.previousYearReturnRatio }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 坏账率（%）：
          </template>
          {{ applydata.badDebtsRatio }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"
            ><i style="color: red">*</i> 前一年度纠纷比率（%）：
          </template>
          {{ applydata.previousYearDisputeRatio }}
        </el-descriptions-item>
        <el-descriptions-item
          :span="3"
          :contentStyle="{ 'text-align': 'center' }"
          label-class-name="my-labels"
        >
          <template slot="label"
            ><i style="color: red">*</i> 营业执照：
          </template>
          <el-image
            style="width: 164px; height: 91px; border-radius: 4px"
            :src="applydata.businessLicenseUrl"
            fit="cover"
            :preview-src-list="[applydata.businessLicenseUrl]"
          >
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item
          :span="4"
          :contentStyle="{ 'text-align': 'center' }"
          label-class-name="my-labels"
        >
          <template slot="label"
            ><i style="color: red">*</i> 资料上传：
          </template>
          <el-image
            v-if="applydata.receiptInvoiceUrl"
            style="width: 164px; height: 91px; border-radius: 4px"
            :src="applydata.receiptInvoiceUrl"
            fit="cover"
          >
            <div slot="error" style="height: 100%" class="image-slot">
              <div
                class="error_img"
                @click="openurl(applydata.receiptInvoiceUrl)"
              ></div>
            </div>
          </el-image>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="header_title">
      授信信息<span v-if="alldata.applyStatus == 0" class="red_title">
        (待处理状态下暂无授权相关信息)
      </span>
    </div>
    <div class="car">
      <el-descriptions
        v-if="alldata.applyStatus != 0"
        class="margin-top"
        :column="7"
        size="medium"
        border
      >
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"> 授信申请编号： </template>
          {{ alldata.applyCode }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"> 额度状态： </template>
          {{ alldata.applyStatus == 1 ? "审核通过" : "已拒绝" }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="alldata.applyStatus == 1"
          :span="3"
          label-class-name="my-labels"
        >
          <template slot="label"> 授信额度： </template>
          {{ alldata.creditLine }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="alldata.applyStatus == 1"
          :span="4"
          label-class-name="my-labels"
        >
          <template slot="label"> 可用额度： </template>
          {{ alldata.availableQuota }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="alldata.applyStatus == 1"
          :span="3"
          label-class-name="my-labels"
        >
          <template slot="label"> 授信起始日期： </template>
          {{
            alldata.creditStartTime.replace("T", " ").replace("00:00:00", "")
          }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="alldata.applyStatus == 1"
          :span="4"
          label-class-name="my-labels"
        >
          <template slot="label"> 授信结束日期： </template>
          {{ alldata.creditEndTime.replace("T", " ").replace("00:00:00", "") }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="header_title">关联的服务信息</div>
    <div class="info">
      <div class="relation">
        <div class="left pointer" @click="todetail">
          <div class="img">
            <img class="img" :src="alldata.mainGraph" />
          </div>
          <div class="every">
            <div class="first">{{ alldata.name }}</div>
            <div class="secend">
              服务类别：{{ alldata.serviceType }} | 单价：{{
                alldata.goodsPrice + alldata.unit
              }}
            </div>
            <div class="thired">{{ alldata.createTime }}</div>
          </div>
        </div>
        <div class="right">
          <div class="tag">{{ alldata.onShelf ? "销售中" : "已下架" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { detailServiceOrder } from "@/api/home";
const { baseUrl } = require("@/config/index.js");
export default {
  data() {
    return {
      applyStatus: ["待处理", "已通过", "已拒绝"],
      alldata: {
        creditStartTime: "",
        creditEndTime: "",
        creditStartTime: "",
      },
      applydata: {
        auditTime: "",
      },
      srcList: [
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
    };
  },
  mounted() {
    this.getdetail();
  },
  methods: {
    openurl(url) {
      window.open(url, "_blank");
    },
    getdetail() {
      detailServiceOrder(this.$route.query.id).then((res) => {
        if (res.code == 0) {
          this.alldata = {
            ...res.data.serviceOrderVO,
            ...res.data.finaceInfo,
            mainGraph:
              baseUrl +
              "third/third/ossGetFile/" +
              res.data.serviceOrderVO.mainGraph,
            goodsPrice: res.data.serviceOrderVO.goodsPrice
              ? res.data.serviceOrderVO.goodsPrice
              : "面议",
            createTime: res.data.serviceOrderVO.createTime.replace("T", " "),
            finishTime: res.data.serviceOrderVO.finishTime
              ? res.data.serviceOrderVO.finishTime.replace("T", " ")
              : "",
          };
          this.applydata = {
            ...res.data.applyQuota,
            receiptInvoiceUrl: this.hasurl(
              res.data.applyQuota.receiptInvoiceUrl
            ),
            businessLicenseUrl: this.hasurl(
              res.data.applyQuota.businessLicenseUrl
            ),
            applyDate: res.data.applyQuota.applyDate.replace("T", " "),
            registerTime: res.data.applyQuota.registerTime
              ? res.data.applyQuota.registerTime.replace("T", " ")
              : "",
          };
          // console.log(this.applydata, "this.applydata");
        }
      });
    },
    hasurl(url) {
      return url.indexOf("http") == -1
        ? baseUrl + "third/third/ossGetFile/" + url
        : url;
    },
    todetail() {
      this.$router.push({
        path: "/myPublisheddetail",
        query: {
          id: this.alldata.serviceGoodId,
        },
      });
    },
  },
};
</script>
<style>
.my-labels {
  width: 284px;
  text-align: right !important;
}
.my-label {
  width: 176px;
  text-align: right !important;
}
.label_right {
  width: 205px;
  text-align: right !important;
}
</style>
<style lang="scss" scoped>
.error_img {
  height: 100%;
  width: 100%;
  background: url("../../../assets/img/rar.png");
  background-size: 100% 100%;
}
.center {
  width: 1195px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .info {
    .relation {
      display: flex;
      justify-content: space-between;
      padding: 17px;
      .left {
        display: flex;
        .img {
          width: 167px;
          height: 97px;
          border-radius: 2px;
        }
        .every {
          padding-left: 17px;
          .first {
            height: 20px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
          }
          .secend {
            height: 15px;
            font-size: 14px;
            margin: 13px 0 31px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
          }
          .thired {
            height: 9px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #787b80;
          }
        }
      }
      .right {
        .tag {
          width: 68px;
          height: 22px;
          text-align: center;
          line-height: 20px;
          color: #188827;
          border: 1px solid #188827;
          border-radius: 2px;
        }
      }
    }
  }
  .header_title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    padding-left: 15px;
    height: 45px;
    line-height: 45px;
    background: #fafafa;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    .red_title {
      font-size: 12px;
      color: #ff0000;
    }
  }
  .company {
    padding: 23px 13px 20px;
    .name {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .tell {
      margin-top: 15px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
  .order {
    display: flex;
    padding: 20px 0;
    .left {
      width: 267px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .table {
      width: 900px;
      padding-top: 14px;
    }
  }
  .car {
    padding: 23px 112px;
  }
}
</style>>